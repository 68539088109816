<template>
    <section id="Digital" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Watch the virtual world come to reality</h2>
          <p style="padding-top:20px">Our engineers work with several businesses creating an integrated data system using digital skills. Our company offers digital engineering services; keeping up with the market trends and the demands of the customers in a digital world.</p>
          <p>They aim to provide and promote an ultimate fully functional product solution engineered digitally to fit the current virtual world of digital hand that extends as an essential tool in all our day-to-day activities.</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-3 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4 class="digitalhead">{{ obj.header }}</h4>
              <h6 class="digitalsubhead">{{obj.subtitile}}</h6>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/TECHNOLOGY.jpg',
        header: 'EVOLUTION OF THE DIGITAL WORLD',
        subtitile: 'We deliver digitized solutions',
        caption: 'Our professionals are creative and from diverse backgrounds. They keep up with the technology to create new digital processes. Considering the digital world, technologies are always replaced as human mind and machines work and co-ordinate to provide solutions.'
      },
      {
        path: 'services/EVOLVING.jpg',
        header: 'THE EVER EVOLVING DATA',
        subtitile: 'Data manipulation the key',
        caption: 'Product life cycle management is one of the important factors in digital engineering. Working with data requires that continuous stream of data flows throughout the product cycle. Manipulating and converting the data into a functioning digitally engineered product is the main function.'
      },
      {
        path: 'services/EVOLUTION.jpg',
        header: 'TECHNOLOGY SAVVY PROFESSIONALS',
        subtitile: 'We have the professionals',
        caption: 'Our technology savvy professionals work with passion and dedication towards their goals of achievements. They make use of aided simulated virtual world and design innovative solution-driven products.'
      },
      {
        path: 'services/CREATIVE.jpg',
        header: 'CREATIVE AND DIVERSE',
        subtitile: 'We are creative',
        caption: 'There are no set of rules to decide on the capability of digital engineers as they are from various backgrounds of technology and are diverse in their thinking. This leads to finding innovative solutions.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Digital {
    padding: 50px 30px 10px 30px;
}
.services .icon-box i {
  float: left;
  color: #f6b024;
  font-size: 40px;
  line-height: 0;
}
.digitalsubhead{
  font-size: 14px;
    line-height: 20px;
}

.services .icon-box .digitalhead {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 25px;
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>