<template>
    <section id="Digitalicon" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h4>We deliver digitized solutions</h4>
          <p>Our professionals are creative and from diverse backgrounds. They keep up with the technology to create new digital processes.</p>
       </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
              <Image :fpath=" obj.path " class="Digitalimage"/>
              <h4 class="embservice">{{ obj.header }}</h4>
              <p class="embservice">{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
         path: 'background/graphic-design.png',
        header: 'Digitally designed tools',
        caption: 'Tools available in the digital world help engineers test their coded software. The virtual world of 3D model provides them with an open access to judge the end-result of their designed product.'
      },
      {
         path: 'background/digital.png',
        header: 'Being digitally ready',
        caption: 'Digital engineering solutions help in the development of building constructions and planning in advance the infrastructure of roads or real estates. It can help manage the once hard planning and thinking that went behind constructions into a matter of minutes of being digitally ready.'
      },
      {
         path: 'background/assets.png',
        header: 'Manage asset digitally',
        caption: 'Private sectors are always on the lookout for improving on their asset management by any means possible. Digital engineered solutions provide them with endless opportunities to manage all the functions on the property. It is a growing business field.'
      }
    ]

  })
    
}
</script>
<style>

  .services .icon-box img {
    float: left;
    margin-top: 10px;
  }

  img.Digitalimage {
    width: 15%;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Digitalicon {
    padding: 50px 30px 10px 30px;
}
.services .icon-box i {
  float: left;
  color: #f6b024;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
    margin-top: 10px;
}
.embservice{
  margin-left: 70px;
}
.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>