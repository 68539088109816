<template>
    <Header/>
    <DigitalEngineeringheader/>
    <DigitalEngineeringSection/>
    <Digitalicons/>
    <Footer/>
</template>

<script>
// @ is an alias to /src

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import DigitalEngineeringSection from '@/components/DigitalEngineeringSection.vue'
import DigitalEngineeringheader from '@/components/DigitalEngineeringheader.vue'
import Digitalicons from '@/components/Digitalicons.vue'

export default {
  components: {
     Header,
    Footer,
    DigitalEngineeringSection,
    DigitalEngineeringheader,
    Digitalicons
  }
}
</script>